import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { dispatchEventBus } from '@appbase/util/eventBus';
import { mapGetters } from 'vuex'
import * as usertypes from '@account/store/types'
import api from '@gnb/api'
import PopupLayout from '@gnb/views/PopupLayout.vue'
import GnbQuickMenuUserSet from '@gnb/components/GlobalNavigationBarLeft/GnbQuickMenuUserSet.vue'

const GNB_QUICKMENU_USERSET = '$gnb/pop/setquickmenu';

@Component({
    components: {
        PopupLayout,
        GnbQuickMenuUserSet
    },
    computed: {
        ...mapGetters({
          userCurrent: usertypes.CURRENT
        }),
    }
})
export default class QuickMenuSetPopView extends Vue {
    userCurrent!: account.TCurrent
    quickMenuUserSet: gnb.QuickMenuItem[] = [];
    userLang: string = ''

    fnGetQuickMenuUserSet(){
        this.quickMenuUserSet = [];
        api.gnb.getUserQuickMenuList(this.userCurrent.user.personCode, this.userCurrent.user.comCode, 'S').then((res: any) => {
            if (res){
                this.quickMenuUserSet = res;
            }
        });
    }

    async fnSubmit(){
        if (await(window as any).confirmAsync(this.$t('저장_하시겠습니까').toString())){
            let arrQuickMenuIds: Number[] = []
            this.quickMenuUserSet.filter(function(item){
                if (item.userSet) arrQuickMenuIds.push(item.quickMenuId)
                if(item.hasChildren){
                    item.childItems.filter(function(itm){
                        if (itm.userSet) arrQuickMenuIds.push(itm.quickMenuId)
                    })
                }
            });
        
            dispatchEventBus(window.opener, GNB_QUICKMENU_USERSET, arrQuickMenuIds);
            window.close();
        }
    }

    fnClose(){
        window.close()
    }

    created(){
        this.setPage({title: this.$t('퀵메뉴_설정').toString()})
        this.userLang = this.userCurrent.userConfig.language
        this.fnGetQuickMenuUserSet();
    }
}
