import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
import * as typesAppbase from '@appbase/store/types'

@Component({
    computed: {
        ...mapGetters({
            page: typesAppbase.PAGE_CURRENT,
        }),
    }
})
export default class PopupLayout extends Vue {}