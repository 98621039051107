import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class GnbQuickMenuUserSet extends Vue {
    @Prop({type:Array}) quickMenuUserSet!: gnb.QuickMenuItem[]
    @Prop({type:String}) userLang!: string

    fnGetQuickMenuName(item: gnb.QuickMenuItem){
        let menuName: string = ''
        if (item){
            menuName = (this.userLang.toLowerCase() == 'ko-kr' ? item.title : item.titleEn).toString()
        }
        return menuName
    }
}